// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Collapse from 'antd/lib/collapse'
import 'antd/lib/collapse/style/css'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Aura FAQ',
  slug: '/frequently-asked-questions',
  abstract: 'Aura FAQ',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Aura FAQ', slug: '/faq' },
  ],
}

const { Panel } = Collapse

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    longBanner: file(relativePath: { eq: "faq/long-banner.jpg" }) {
      ...max3000
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="faqs" pageSchema={pageSchema} {...props}>
    <div
      style={{
        borderTop: '4px solid var(--gold2)',
        width: '100%',
        marginBottom: '2.2rem',
        padding: '1.1rem 0rem',
      }}
    >
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <h2 style={{ textAlign: 'right', marginRight: '1.1rem' }}>
            The Aura Network
            <br />
            <small>Down to Basics</small>
          </h2>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Collapse className="as-paragraph" ghost>
            <Panel
              header={<h3 style={{ marginBottom: 0 }}>What is the Aura?</h3>}
              key="1"
            >
              <p>
                It is a virtual community credit created by members of the Aura
                Network. The value of Aura is created in each instance where
                goodwill is turned into action. The action of the member is to
                identify, transform and circulate an array of underutilised
                resources.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Can Auras be exchanged for real money?
                </h3>
              }
              key="2"
            >
              <p>
                No, the Aura cannot be exchanged with any currency like Euro or
                Rupees.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Why not just a barter system?
                </h3>
              }
              key="3"
            >
              <p>
                Barter is usually bargaining, based on conventional values and
                limited in scope. The Aura Network aspires to embrace conscious
                sharing and caring for all.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Will Aura impact private and personal sharing?
                </h3>
              }
              key="4"
            >
              <p>
                Users will, of course, continue their usual patterns of
                generosity, but the Aura Platform will encourage them to extend
                their reach by creating a Network that can easily be accessed by
                more people and create an extended sense of community.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  How does the Aura circular economy flow?
                </h3>
              }
              key="5"
            >
              <p>
                Aura breaks free from the linear value cycle where goods are
                used and turned into waste. The Aura creates a space for a
                circular economy where things considered waste, or things that
                are not being purposed can first be identified and then
                up-cycled and repurposed.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Who can use the Aura Network?
                </h3>
              }
              key="6"
            >
              <p>
                At the moment this app is exclusive for the residents of the
                Auroville community.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>Who is the Aura Network?</h3>
              }
              key="7"
            >
              <p style={{ marginBottom: 0 }}>
                Behind the Aura Network is a team organised by a group of
                Aurovilians and Volunteers. The Aura Network is included as an
                activity under the Unity Fund and belongs to Humanity as a
                whole. The project has been made possible by a research grant to
                the Korean Pavillion from the Science Walden Center in Korea.
              </p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
    <div
      style={{
        borderTop: '4px solid var(--gold2)',
        width: '100%',
        marginBottom: '2.2rem',
        padding: '1.1rem 0rem',
      }}
    >
      <Row
        gutter={[
          { xs: 8, sm: 12, md: 16, lg: 20, xl: 24, xxl: 28 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <h2 style={{ textAlign: 'right' }}>
            Aura, the digital currency
            <br />
            <small>A Deep-Dive</small>
          </h2>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Collapse className="as-paragraph" ghost>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>Is Aura ‘fake’ money?</h3>
              }
              key="1"
            >
              <p>
                Money is a sort of language. We agree on its meaning and its
                agreement and that is why it has value. It may not seem like it,
                but the same principle applies to fiat currencies like the Euros
                or Indian Rupees. Source: our friends at{' '}
                <Link to="https://joincircles.net/">Circles Berlin</Link>.
              </p>
              <p>
                Aura’s value is based on the amount of interactions taking place
                on the Network. If you offer your used bike, translate a text
                for someone, perform a repair and accept Auras as compensation,
                this value exchange occurring in the community stays in the
                Network and continues to circulate.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Is Aura going to replace fiat currency like the Indian Rupee?
                </h3>
              }
              key="2"
            >
              <p>
                No. Aura is a complementary currency that is meant to exist in
                parallel to any existing national currencies. Aura is a currency
                that cannot be used to access certain goods and services such as
                petrol or paying your Internet provider, which require payment
                in Rupees. The intention of Aura as a complementary currency is
                to preserve value that is created through community interactions
                by letting it circulate and remain within the local context of
                Auroville.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Why do I have to select members to share my Auras with?
                </h3>
              }
              key="3"
            >
              <p>
                Aura strives to reshape money from being a tool to accumulate
                individual wealth and exercise individual status. Our vision is
                to create a new form of money that could be used as a tool for
                collective exchange and sharing.
              </p>
              <p>
                Think of it this way, Aura is not *my/your* currency. It’s *our*
                currency. Just as Auroville does not belong to anyone in
                particular, but to Humanity as a whole, so does Aura!
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Isn’t it better to create a platform for the exchange of goods
                  and services between community members without the need to
                  introduce a new form of money?
                </h3>
              }
              key="4"
            >
              <p>
                The Aura is a collectively driven currency that does not draw
                any reference from pre-established market determined prices. In
                simpler terms, Aura is a currency that is given out as
                unconditional basic income (= free money for all), which creates
                a sense of freedom and playfulness for each user to value their
                offerings in a unique way that is is line with a person’s
                subjective valuation process. We believe that Aura can be used
                as a tool to uniquely value offerings which creates a sense of
                fairness during exchanges that is often missing in a purely
                barter system.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>What can I buy with Auras?</h3>
              }
              key="5"
            >
              First off, we have consciously decided not to use commercially
              oriented language on the Network.
              <p>
                The concept of Aura is not to "<i>buy</i>", but rather to “
                <i>access</i>” the existing wealth and abundance in the
                community, and “<i>receive</i>” what a community has to offer.
              </p>
              <p>
                The concept of Aura is not to “<i>pay</i>”, but to “
                <i>contribute</i>” Auras in gratitude for a member’s act of
                goodwill.
              </p>
              <p>
                Back to the question at hand, the simple answer is:{' '}
                <strong>it all depends on you</strong>! The wealth of the Aura
                Network depends on the amount of goodwill that is manifested
                through real action— in other words, how many participants in
                our social/economic experiment will offer goods and services on
                the Network for others to access.
              </p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
    <div
      style={{
        borderTop: '4px solid var(--gold2)',
        width: '100%',
        marginBottom: '2.2rem',
        padding: '1.1rem 0rem',
      }}
    >
      <Row
        gutter={[
          { xs: 8, sm: 12, md: 16, lg: 20, xl: 24, xxl: 28 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <h2 style={{ textAlign: 'right' }}>
            The Aura Network App
            <br />
            <small>Navigating the App</small>
          </h2>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Collapse className="as-paragraph" ghost>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  How to get and use the Aura Network App?
                </h3>
              }
              key="8"
            >
              <p>
                The Aura Network App can be downloaded from the Play Store for
                Android devices, and the App Store for iOS devices. When the app
                is downloaded, a standard signup and choice of password will
                appear. To start engaging with on app, an administrator will
                approve your request to join the network.
              </p>
            </Panel>
            <Panel
              header={<h3 style={{ marginBottom: 0 }}>Signup and verify.</h3>}
              key="k1"
            >
              <p>
                The Aura Network app has been created in Auroville for
                Aurovilians, Newcomers and Long-term Volunteers. A profile
                containing a short bio and a portrait photo will help the
                community get to know you better.
              </p>
            </Panel>
            <Panel
              header={<h3 style={{ marginBottom: 0 }}>Daily Auras for you.</h3>}
              key="k2"
            >
              <p>
                You get 12 Auras every day as Aura Basic Income to access a
                range of offerings from other members in the Aura Network. You
                will find that people post "Offers" to express what they can
                share, and "Needs" to express what they require.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Posting your offers and needs.
                </h3>
              }
              key="k3"
            >
              <p>
                Feel free to offer any item, service, skill, space that you can.
                You can request for any item, services or assitance you may
                need, and reach out to everyone in the network. Through
                offerings and helping others in covering their needs, you will
                recieve Generosity Auras.
              </p>
            </Panel>
            <Panel
              header={<h3 style={{ marginBottom: 0 }}>Chat away.</h3>}
              key="k4"
            >
              <p>
                You can connect and chat with people about items or services
                that you are interested in. In additon to this, users can also
                use the chat feature to contribute Auras, confirm delivery of an
                offer and/or post a review.
              </p>
            </Panel>
            <Panel
              header={
                <h3 style={{ marginBottom: 0 }}>
                  Network of peers and sharing.
                </h3>
              }
              key="k5"
            >
              <p>
                Choose your Aura Peers in the Network and share with them the
                Aura Basic Income you recieve unconditionally every day. Out of
                the 12 Auras you recieve, you share 3 Auras with the peers
                you've selected.
              </p>
            </Panel>
            <Panel
              header={<h3 style={{ marginBottom: 0 }}>Aura account.</h3>}
              key="k6"
            >
              <p>
                Your account is where you can find out how much value you
                created every day. You will have an "Aura Income" and a
                "Generosity Income".
              </p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
